<template>
  <sub-page
      :title="$t('General')"
      icon="mdi-cogs"
      v-model="$store.state.settings.pageData"
  >
    <ws-accordion
        class="mt-5"
        :items="navigationItems"
    >
      <!-- Actions-->
      <template #action.alias="{forcedExpand , item}">
        <v-chip
            @click="openAliasDialog(forcedExpand)"
            dark
            :color="wsACCENT"
            class="px-1 justify-end font-weight-medium pointer mr-5"
        >
          <v-icon small class="ml-2">mdi-{{ !item.expand ? 'pencil' : 'close' }}</v-icon>
          <h5  class="mx-2"> {{ !item.expand  ? $t('Edit') : $t('Cancel') }}</h5>

        </v-chip>
      </template>
      <template #action.description="{forcedExpand , item}">
        <v-chip
            @click="openDescription(forcedExpand)"
            dark
            :color="wsACCENT"
            class="px-1 justify-end font-weight-medium pointer mr-5"
        >
          <v-icon small class="ml-2">mdi-{{ !item.expand ? 'pencil' : 'close' }}</v-icon>
          <h5  class="mx-2"> {{ !item.expand  ? $t('Edit') : $t('Cancel') }}</h5>
        </v-chip>
      </template>
      <template #action.type>
        <div class="fill-height align-center d-flex  pr-5">

          <ws-select
              :placeholder="$t('homepage.register.business.type.placeholder')"
              v-model="businessType"
              :items="businessTypesSelect"
              width="300"
              hide-details
          />
        </div>

      </template>

      <!-- Items-->
      <template #item.alias>
        <div class="pa-5">
          <h4 class="font-weight-regular">
            {{ $t('ChangeBusinessAliasWarning') }}
          </h4>
          <ws-text-field
              v-model="alias"
              class="mt-6"
              :label="$t('Alias')"
              width="400"
              :error-messages="aliasError"
              @input="aliasError = null"
              :error="!!aliasError"
              :hide-details="false"
          />
          <ws-button
              @click="openAliasChangeDialog"
              :disabled="alias===$store.state.business.selectedBusiness.alias || !!aliasError || !alias || alias.length < 3"
              label="Save"
              class="mt-2"
          />
        </div>

      </template>

      <template #item.image>
        <v-sheet height="300" :color="wsATTENTION" class="pa-6">
          <h1>Change image</h1>
        </v-sheet>
      </template>
      <template #item.description>
        <div class="pa-5 ">
          <div
              v-for="(lang,i) in langs" :key="lang"
              class="d-flex"
              :class="[{ 'mt-10' : i>0 }]"
              style="width: 100%">
            <div
                style="width: 100%" class="mr-6">
              <ws-text-field
                  @change="changeTranslation('name' , lang , $event )"
                  :value="names[lang]"
                  :label="`${$t('Title')} (${LANGUAGE_TEXT(lang)})`"
                  width="100%"
                  class="mr-6"
              />
              <ws-text-field
                  @change="changeTranslation('description' , lang , $event )"
                  :value="descriptions[lang]"
                  :label="`${$t('Description')} (${LANGUAGE_TEXT(lang)})`"
                  area
                  width="100%"
                  class="mr-6 mt-5"
              />
            </div>

            <v-btn :color="wsACCENT" icon class="mt-5">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>

        </div>

      </template>
      <template #item.type>
        <ws-text-field
            :label="$t('UA')"
        />
      </template>

    </ws-accordion>

    <template #dialog>
      <ws-dialog
          v-model="displayAliasDialog"
          :title="$t('EditBusinessAlias')"
          :save-text="$t('Confirm')"
          @save="saveAlias"
      >
        <h5 class="font-weight-regular">{{  $t('ChangeAliasConfirmationDescription')  }}:</h5>
        <h5 class="mt-2">{{  aliasConfirmationCode  }}</h5>

        <ws-text-field
            v-model="aliasConfirmation"
            class="mt-5"
            :placeholder="$t('EnterConfirmationCode')"
            :error="!!aliasConfirmationError"
            :error-messages="aliasConfirmationError"
            @input="aliasConfirmationError = null"
        />

      </ws-dialog>
    </template>
  </sub-page>
</template>

<script>
import languagesList from "@/mixins/languagesList";
import {mapActions} from "vuex";
export default {
  name: "settingsSite",
  mixins : [languagesList],
  data() {
    return {
      serviceKeys : {},
      // alias
      alias : '',
      aliasError : null,
      aliasConfirmationError : null,
      aliasConfirmationCode : null,
      aliasConfirmation : null,
      displayAliasDialog : false,
      // description
      langs  : [],
      names : {},
      descriptions : {}
    }
  },
  computed : {
    navigationItems() {
      return [
        { name : this.$t('homepage.register.business.alias.title') ,
          subtitle : this.$t('homepage.register.business.alias.tooltip'),
          value : 'alias' ,
          icon : 'mdi-link',
          expand : false,
          noExpand: true,
          expanded : false
        },
        { name : this.$t('homepage.register.business.name.title') ,
          subtitle : this.$t('homepage.register.business.name.tooltip'),
          value : 'description' ,
          icon : 'mdi-information-outline',
          noExpand: true,
          expanded : false
        },
        // { name : this.$t('Image') ,
        //   subtitle : this.$t('BusinessImageDescription'),
        //   value : 'image' ,
        //   icon : 'mdi-image',
        //   noExpand: true,
        //   expanded : false
        // },
        // { name : this.$t('homepage.register.business.type.title') ,
        //   subtitle : this.$t('homepage.register.business.type.tooltip'),
        //   value : 'type' ,
        //   icon : 'mdi-format-list-bulleted-type',
        //   noExpand: true,
        //   expanded : false
        // },
      ]
    },
    businessTypesSelect() {
      return [
        { text : this.$t("PrivateSchool"      ),   value : 'private_school',    },
        { text : this.$t("BusinessTrainings"  ),   value : 'business_trainings',},
        { text : this.$t("PsychologyCourses"  ),   value : 'psychology_courses',},
        { text : this.$t("ImprovementCourses" ),   value : 'upgrade',           },
        { text : this.$t("ChildCourses"       ),   value : 'children',          },
        { text : this.$t("FitnessSportTeacher"),   value : 'fitnes',            },
        { text : this.$t("ArtCourses"         ),   value : 'art',               },
        { text : this.$t("SchoolTeacher"      ),   value : 'school_teacher',    },
      ]
    }
  },
  methods : {
    ...mapActions('business',  [ 'CHECK_ALIAS' ]) ,
    ...mapActions('settings',  [
        'EDIT_BUSINESS_GENERAL_SETTINGS',
        'GET_BUSINESS_TRANSLATIONS_ARRAY'
    ]) ,

    generateCode() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';

      let code = '';

      // Generate two random letters
      for (let i = 0; i < 2; i++) {
        code += letters.charAt(Math.floor(Math.random() * letters.length));
      }

      // Generate four random numbers
      for (let i = 0; i < 6; i++) {
        code += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }

      return code;
    },

    async changeTranslation(type , lang , value ) {
      let data = {
        [type] : value,
        lang : lang
      }
      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS(data)
      if ( !result ) {
        return
      }
    },

    async openDescription(expand) {
      let result = await this.GET_BUSINESS_TRANSLATIONS_ARRAY()
      if ( !result ) {
        return
      }
      this.langs = result.langs
      this.names = result.names
      this.descriptions = result.descriptions
      expand()
    },

    async saveAlias() {

      if ( this.aliasConfirmationCode !== this.aliasConfirmation ) {
        this.aliasConfirmationError = this.$t('WrongConfirmationCode')
        this.notify(this.$t('WrongConfirmationCode') , 'warning')
        return
      }

      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS({ alias : this.alias })
      if ( !result ) {
        return
      }
      this.notify(this.$t('BusinessAliasChanged') , 'success')
      this.$store.state.business.selectedBusiness.alias = this.alias
      this.$router.push(this.businessDashLink('settings/general'))
      location.reload()
    },
    async openAliasChangeDialog() {
      if ( !this.alias ) {
        this.aliasError = this.$t('PleaseEnterBusinessAlias')
        this.notify(this.$t('PleaseEnterBusinessAlias') , 'warning')
        return
      }
      if ( !this.alias.match("^[a-zA-Z0-9_-]*$")) {
        this.aliasError = this.$t('AliasRegexError')
        return this.notify(this.$t('AliasRegexError'), 'warning')
      }

      if ( this.alias.length < 3) {
        this.aliasError = this.$t('AliasLengthError')
        return this.notify(this.$t('AliasLengthError'), 'warning')
      }

      if ( !await this.CHECK_ALIAS(this.alias) ) {
        this.aliasError = this.$t('BusinessAliasAlreadyExist')
        return this.notify(this.$t('BusinessAliasAlreadyExist'), 'warning')
      }

      this.aliasConfirmation = ''
      this.aliasConfirmationCode = this.generateCode()
      this.displayAliasDialog = true

    },
    openAliasDialog(expand) {
      this.alias = this.$store.state.business.selectedBusiness.alias
      expand()
    },
    addDescriptionLanguage() {

    },
    addTitleLanguage() {

    },
    initPage() {
      this.serviceKeys.gTag = !!this.$store.state.business.selectedBusiness.gTag
      this.serviceKeys.fPixelId = !!this.$store.state.business.selectedBusiness.fPixelId
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>